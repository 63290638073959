.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0.42);
}
.theme--light.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-text-field.v-input--is-disabled .v-input__slot::before {
  border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(0, 0, 0, 0.06);
}
.theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: rgba(0, 0, 0, 0.12);
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #FFFFFF;
}
.theme--light.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  background: rgba(0, 0, 0, 0.06);
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #424242;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input {
  color: #FFFFFF;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot .v-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:hover fieldset {
  color: rgba(0, 0, 0, 0.86);
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled > .v-input__control > .v-input__slot fieldset {
  color: rgba(0, 0, 0, 0.26);
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme--dark.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
  border-color: #FFFFFF;
}
.theme--dark.v-text-field.v-input--is-disabled .v-input__slot::before {
  border-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.5) 0px, rgba(255, 255, 255, 0.5) 2px, transparent 2px, transparent 4px) 1 repeat;
}
.theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.08);
}
.theme--dark.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: rgba(255, 255, 255, 0.16);
}
.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #1E1E1E;
}
.theme--dark.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.16);
}
.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #FFFFFF;
}
.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input {
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot .v-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: rgba(255, 255, 255, 0.24);
}
.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) > .v-input__control > .v-input__slot:hover fieldset {
  color: #FFFFFF;
}
.theme--dark.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled > .v-input__control > .v-input__slot fieldset {
  color: rgba(255, 255, 255, 0.16);
}

.v-text-field {
  padding-top: 12px;
  margin-top: 4px;
}
.v-text-field__prefix, .v-text-field__suffix {
  line-height: 20px;
}
.v-text-field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}
.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-radius: inherit;
}
.v-text-field fieldset,
.v-text-field .v-input__control {
  color: currentColor;
}
.v-text-field.v-input--has-state .v-input__control > .v-text-field__details > .v-counter {
  color: currentColor;
}
.v-text-field.v-input--is-disabled .v-input__control > .v-text-field__details > .v-counter,
.v-text-field.v-input--is-disabled .v-input__control > .v-text-field__details > .v-messages {
  color: currentColor;
}
.v-text-field.v-input--dense {
  padding-top: 0;
}
.v-text-field.v-input--dense .v-label {
  top: 4px;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 4px 0 2px;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix {
  padding-right: 4px;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix {
  padding-left: 4px;
}
.v-text-field.v-input--dense[type=text]::-ms-clear {
  display: none;
}
.v-text-field.v-input--dense .v-input__prepend-inner,
.v-text-field.v-input--dense .v-input__append-inner {
  margin-top: 0px;
}
.v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  align-self: flex-start;
  display: inline-flex;
  margin-top: 4px;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
}
.v-application--is-rtl .v-text-field .v-input__prepend-inner {
  margin-left: auto;
  padding-left: 4px;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
  margin-left: auto;
  padding-left: 4px;
}
.v-application--is-rtl .v-text-field .v-input__append-inner {
  margin-right: auto;
  padding-right: 4px;
}
.v-text-field .v-counter {
  white-space: nowrap;
}
.v-application--is-ltr .v-text-field .v-counter {
  margin-left: 8px;
}
.v-application--is-rtl .v-text-field .v-counter {
  margin-right: 8px;
}
.v-text-field .v-label {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  white-space: nowrap;
  pointer-events: none;
}
.v-application--is-ltr .v-text-field .v-label {
  transform-origin: top left;
}
.v-application--is-rtl .v-text-field .v-label {
  transform-origin: top right;
}
.v-text-field .v-label--active {
  max-width: 133%;
  transform: translateY(-18px) scale(0.75);
  pointer-events: auto;
}
.v-text-field > .v-input__control > .v-input__slot {
  cursor: text;
}
.v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: solid;
  border-width: thin 0 0 0;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: currentColor;
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 thin 0;
  transform: scaleX(0);
}
.v-text-field__details {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 14px;
  overflow: hidden;
}
.v-text-field__prefix, .v-text-field__suffix {
  align-self: center;
  cursor: default;
  transition: color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
.v-application--is-ltr .v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
}
.v-application--is-rtl .v-text-field__prefix {
  text-align: left;
  padding-left: 4px;
}
.v-text-field__suffix {
  white-space: nowrap;
}
.v-application--is-ltr .v-text-field__suffix {
  padding-left: 4px;
}
.v-application--is-rtl .v-text-field__suffix {
  padding-right: 4px;
}
.v-application--is-ltr .v-text-field--reverse .v-text-field__prefix {
  text-align: left;
  padding-right: 0;
  padding-left: 4px;
}
.v-application--is-rtl .v-text-field--reverse .v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
  padding-left: 0;
}
.v-application--is-ltr .v-text-field--reverse .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.v-application--is-rtl .v-text-field--reverse .v-text-field__suffix {
  padding-left: 4px;
  padding-right: 0;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.v-text-field:not(.v-text-field--is-booted) .v-label,
.v-text-field:not(.v-text-field--is-booted) legend {
  transition: none;
}
.v-text-field--filled, .v-text-field--full-width, .v-text-field--outlined {
  position: relative;
}
.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 56px;
}
.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot {
  min-height: 52px;
}
.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 40px;
}
.v-text-field--outlined {
  border-radius: 4px;
}
.v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 17px;
}
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__prepend-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-outer {
  margin-top: 14px;
}
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-outer {
  margin-top: 9px;
}
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer {
  margin-top: 8px;
}
.v-text-field--filled .v-label, .v-text-field--full-width .v-label {
  top: 18px;
}
.v-text-field--filled .v-label--active, .v-text-field--full-width .v-label--active {
  transform: translateY(-6px) scale(0.75);
}
.v-text-field--filled.v-input--dense .v-label, .v-text-field--full-width.v-input--dense .v-label {
  top: 17px;
}
.v-text-field--filled.v-input--dense .v-label--active, .v-text-field--full-width.v-input--dense .v-label--active {
  transform: translateY(-10px) scale(0.75);
}
.v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
  top: 11px;
}
.v-text-field--filled {
  border-radius: 4px 4px 0 0;
}
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 22px;
}
.v-text-field--filled.v-input--dense:not(.v-text-field--single-line).v-text-field--outlined input {
  margin-top: 0;
}
.v-text-field--filled .v-text-field__prefix,
.v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 20px;
}
.v-text-field--full-width {
  border-radius: 0;
}
.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
  align-items: center;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}
.v-text-field.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix,
.v-text-field.v-text-field--enclosed.v-text-field--single-line .v-text-field__suffix {
  margin-top: 0;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--filled) .v-progress-linear__background {
  display: none;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 8px;
}
.v-application--is-ltr .v-text-field--reverse input, .v-application--is-ltr .v-text-field--reverse textarea {
  text-align: right;
}
.v-application--is-rtl .v-text-field--reverse input, .v-application--is-rtl .v-text-field--reverse textarea {
  text-align: left;
}
.v-application--is-ltr .v-text-field--reverse .v-label {
  transform-origin: top right;
}
.v-application--is-rtl .v-text-field--reverse .v-label {
  transform-origin: top left;
}
.v-text-field--reverse > .v-input__control > .v-input__slot,
.v-text-field--reverse .v-text-field__slot {
  flex-direction: row-reverse;
}
.v-text-field--outlined > .v-input__control > .v-input__slot:before, .v-text-field--outlined > .v-input__control > .v-input__slot:after, .v-text-field--solo > .v-input__control > .v-input__slot:before, .v-text-field--solo > .v-input__control > .v-input__slot:after, .v-text-field--rounded > .v-input__control > .v-input__slot:before, .v-text-field--rounded > .v-input__control > .v-input__slot:after {
  display: none;
}
.v-text-field--outlined, .v-text-field--solo {
  border-radius: 4px;
}
.v-text-field--outlined {
  margin-bottom: 16px;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field--outlined .v-label {
  top: 18px;
}
.v-text-field--outlined .v-label--active {
  transform: translateY(-24px) scale(0.75);
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 10px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-16px) scale(0.75);
}
.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-application--is-ltr .v-text-field--outlined fieldset {
  padding-left: 8px;
}
.v-application--is-rtl .v-text-field--outlined fieldset {
  padding-right: 8px;
}
.v-application--is-ltr .v-text-field--outlined.v-text-field--reverse fieldset {
  padding-right: 8px;
}
.v-application--is-rtl .v-text-field--outlined.v-text-field--reverse fieldset {
  padding-left: 8px;
}
.v-text-field--outlined legend {
  line-height: 11px;
  padding: 0;
  transition: width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-application--is-ltr .v-text-field--outlined legend {
  text-align: left;
}
.v-application--is-rtl .v-text-field--outlined legend {
  text-align: right;
}
.v-application--is-ltr .v-text-field--outlined.v-text-field--reverse legend {
  margin-left: auto;
}
.v-application--is-rtl .v-text-field--outlined.v-text-field--reverse legend {
  margin-right: auto;
}
.v-application--is-ltr .v-text-field--outlined.v-text-field--rounded legend {
  margin-left: 12px;
}
.v-application--is-rtl .v-text-field--outlined.v-text-field--rounded legend {
  margin-right: 12px;
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: transparent;
}
.v-text-field--outlined .v-text-field__prefix {
  max-height: 32px;
}
.v-text-field--outlined .v-input__prepend-outer,
.v-text-field--outlined .v-input__append-outer {
  margin-top: 18px;
}
.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
  border: 2px solid currentColor;
}
.v-text-field--rounded {
  border-radius: 28px;
}
.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 24px;
}
.v-text-field--shaped {
  border-radius: 16px 16px 0 0;
}
.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 9px);
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}
.v-text-field.v-text-field--solo .v-input__control input {
  caret-color: auto;
}
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 38px;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
}
.v-text-field.v-text-field--solo .v-input__prepend-outer,
.v-text-field.v-text-field--solo .v-input__append-outer {
  margin-top: 12px;
}
.v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer,
.v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
  margin-top: 7px;
}
.v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after {
  transform: scaleX(1);
}
.v-text-field.v-input--has-state > .v-input__control > .v-input__slot:before {
  border-color: currentColor;
}
.v-text-field .v-input__icon--clear {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field.v-input--is-focused .v-input__icon--clear, .v-text-field.v-input--is-dirty:hover .v-input__icon--clear {
  opacity: 1;
}