.theme--light.v-banner.v-sheet {
  background-color: transparent;
}
.theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-banner.v-sheet {
  background-color: transparent;
}
.theme--dark.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
}

.v-sheet.v-banner {
  border-radius: 0;
}
.v-sheet.v-banner:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-banner.v-sheet--shaped {
  border-radius: 16px 0;
}

.v-banner {
  position: relative;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.v-banner__actions {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  margin-bottom: -8px;
}
.v-application--is-ltr .v-banner__actions {
  margin-left: 90px;
}
.v-application--is-rtl .v-banner__actions {
  margin-right: 90px;
}
.v-application--is-ltr .v-banner__actions > * {
  margin-left: 8px;
}
.v-application--is-rtl .v-banner__actions > * {
  margin-right: 8px;
}

.v-banner__content {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.v-banner__text {
  flex: 1 1 auto;
  line-height: 20px;
  max-width: 100%;
}

.v-banner__icon {
  display: inline-flex;
  flex: 0 0 auto;
}
.v-application--is-ltr .v-banner__icon {
  margin-right: 24px;
}
.v-application--is-rtl .v-banner__icon {
  margin-left: 24px;
}

.v-banner__wrapper {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
}
.v-application--is-ltr .v-banner__wrapper {
  padding: 16px 8px 16px 24px;
}
.v-application--is-rtl .v-banner__wrapper {
  padding: 16px 24px 16px 8px;
}

.v-banner--single-line .v-banner__actions {
  margin-bottom: 0;
  align-self: center;
}
.v-banner--single-line .v-banner__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-banner--single-line .v-banner__wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
}

.v-application--is-ltr .v-banner--has-icon .v-banner__wrapper {
  padding-left: 16px;
}
.v-application--is-rtl .v-banner--has-icon .v-banner__wrapper {
  padding-right: 16px;
}

.v-banner--is-mobile .v-banner__actions {
  flex: 1 0 100%;
  margin-left: 0;
  margin-right: 0;
  padding-top: 12px;
}
.v-banner--is-mobile .v-banner__wrapper {
  flex-wrap: wrap;
  padding-top: 16px;
}
.v-application--is-ltr .v-banner--is-mobile .v-banner__wrapper {
  padding-left: 16px;
}
.v-application--is-rtl .v-banner--is-mobile .v-banner__wrapper {
  padding-right: 16px;
}
.v-banner--is-mobile.v-banner--has-icon .v-banner__wrapper {
  padding-top: 24px;
}
.v-banner--is-mobile.v-banner--single-line .v-banner__actions {
  flex: initial;
  padding-top: 0;
}
.v-application--is-ltr .v-banner--is-mobile.v-banner--single-line .v-banner__actions {
  margin-left: 36px;
}
.v-application--is-rtl .v-banner--is-mobile.v-banner--single-line .v-banner__actions {
  margin-right: 36px;
}
.v-banner--is-mobile.v-banner--single-line .v-banner__wrapper {
  flex-wrap: nowrap;
  padding-top: 10px;
}
.v-application--is-ltr .v-banner--is-mobile .v-banner__icon {
  margin-right: 16px;
}
.v-application--is-rtl .v-banner--is-mobile .v-banner__icon {
  margin-left: 16px;
}
.v-application--is-ltr .v-banner--is-mobile .v-banner__content {
  padding-right: 8px;
}
.v-application--is-rtl .v-banner--is-mobile .v-banner__content {
  padding-left: 8px;
}
.v-banner--is-mobile .v-banner__content .v-banner__wrapper {
  flex-wrap: nowrap;
  padding-top: 10px;
}