.theme--light.v-snack__wrapper {
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-snack__wrapper {
  color: #FFFFFF;
}

.v-sheet.v-snack__wrapper {
  border-radius: 4px;
}
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.v-sheet.v-snack__wrapper.v-sheet--shaped {
  border-radius: 16px 4px;
}

.v-snack {
  bottom: 0;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  left: 0;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
}
.v-snack:not(.v-snack--absolute) {
  height: 100vh;
  position: fixed;
  z-index: 1000;
}
.v-snack:not(.v-snack--centered):not(.v-snack--top) {
  align-items: flex-end;
}
.v-snack__wrapper {
  align-items: center;
  border-color: currentColor !important;
  display: flex;
  margin: 8px;
  max-width: 672px;
  min-height: 48px;
  min-width: 344px;
  padding: 0;
  pointer-events: auto;
  position: relative;
  transition-duration: 0.15s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  z-index: 1;
}
.v-snack__wrapper.theme--dark {
  background-color: #333333;
  color: rgba(255, 255, 255, 0.87);
}
.v-snack__content {
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
}
.v-snack__action {
  align-items: center;
  align-self: center;
  display: flex;
}
.v-snack__action .v-ripple__container {
  display: none;
}
.v-application--is-ltr .v-snack__action {
  margin-right: 8px;
}
.v-application--is-rtl .v-snack__action {
  margin-left: 8px;
}
.v-snack__action > .v-snack__btn.v-btn {
  padding: 0 8px;
}
.v-snack__btn {
  margin-left: 0;
  margin-right: 0;
  margin: 0;
  min-width: auto;
}
.v-snack--absolute {
  height: 100%;
  position: absolute;
  z-index: 1;
}
.v-snack--centered {
  align-items: center;
}
.v-snack--left {
  justify-content: flex-start;
  right: auto;
}
.v-snack--multi-line .v-snack__wrapper {
  min-height: 68px;
}
.v-snack--right {
  justify-content: flex-end;
  left: auto;
}
.v-snack:not(.v-snack--has-background) .v-snack__wrapper {
  box-shadow: none;
}
.v-snack--bottom {
  top: auto;
}
.v-snack--text .v-snack__wrapper:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.12;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.v-snack--top {
  align-items: flex-start;
  bottom: auto;
}
.v-snack--vertical .v-snack__wrapper {
  flex-direction: column;
}
.v-snack--vertical .v-snack__wrapper .v-snack__action {
  align-self: flex-end;
  margin-bottom: 8px;
}

.v-snack-transition-enter.v-snack__wrapper {
  transform: scale(0.8);
}
.v-snack-transition-enter.v-snack__wrapper, .v-snack-transition-leave-to.v-snack__wrapper {
  opacity: 0;
}