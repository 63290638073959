.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea {
  color: #FFFFFF;
}
.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme--light.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea {
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea::placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.v-textarea textarea {
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.75rem;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 0;
  width: 100%;
}
.v-textarea .v-text-field__prefix,
.v-textarea .v-text-field__suffix {
  padding-top: 2px;
  align-self: start;
}
.v-textarea.v-text-field--box .v-text-field__prefix,
.v-textarea.v-text-field--box textarea, .v-textarea.v-text-field--enclosed .v-text-field__prefix,
.v-textarea.v-text-field--enclosed textarea {
  margin-top: 24px;
}
.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-text-field__suffix,
.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) textarea, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-text-field__suffix,
.v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-text-field__suffix,
.v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) textarea, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-text-field__suffix,
.v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) textarea {
  margin-top: 10px;
}
.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-label, .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense) .v-label, .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-label, .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-label {
  top: 18px;
}
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-text-field__suffix,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense textarea, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-text-field__suffix,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-text-field__suffix,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense textarea, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-text-field__suffix,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense textarea {
  margin-top: 6px;
}
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__prepend-inner,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__prepend-outer,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__append-inner,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__prepend-inner,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__prepend-outer,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__append-inner,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__prepend-inner,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__prepend-outer,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__append-inner,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense .v-input__append-outer, .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__prepend-inner,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__prepend-outer,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__append-inner,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__append-outer {
  align-self: flex-start;
  margin-top: 8px;
}
.v-textarea.v-text-field--solo {
  align-items: flex-start;
}
.v-textarea.v-text-field--solo .v-input__control textarea {
  caret-color: auto;
}
.v-textarea.v-text-field--solo .v-input__prepend-inner,
.v-textarea.v-text-field--solo .v-input__prepend-outer,
.v-textarea.v-text-field--solo .v-input__append-inner,
.v-textarea.v-text-field--solo .v-input__append-outer {
  align-self: flex-start;
  margin-top: 12px;
}
.v-application--is-ltr .v-textarea.v-text-field--solo .v-input__append-inner {
  padding-left: 12px;
}
.v-application--is-rtl .v-textarea.v-text-field--solo .v-input__append-inner {
  padding-right: 12px;
}
.v-textarea--auto-grow textarea {
  overflow: hidden;
}
.v-textarea--no-resize textarea {
  resize: none;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot {
  align-self: stretch;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: -12px;
}
.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-left: -12px;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 12px;
}
.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-left: 12px;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed.v-text-field--reverse .v-text-field__slot .v-label {
  margin-right: 12px;
}
.v-application--is-rtl .v-textarea.v-text-field--enclosed.v-text-field--reverse .v-text-field__slot .v-label {
  margin-left: 12px;
}